import { Linking, NativeModules, Platform } from 'react-native';
const RNFileViewerModule = NativeModules.RNFileViewerModule;
const openURL = async (options, cb)=>{
  await Linking.openURL(options.url);
  cb();
}


export default {
  openDoc: Platform.OS=="web"? openURL: RNFileViewerModule.openDoc,
  openDocb64: Platform.OS=="web"? openURL: RNFileViewerModule.openDocb64,  
  openDocBinaryinUrl:Platform.OS=="web"? openURL: RNFileViewerModule.openDocBinaryinUrl,
}
